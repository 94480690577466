/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function bigDate(profile, keyPath = 'time.start') {
  const dateString = _getProfileFieldAtKeyPath(profile, keyPath);
  if (!dateString) {
    return null;
  }
  const updateDateString = dateString + "T00:00"; //this puts the date field in the right format

  const date = betterTime(updateDateString); //pass this updatedDateString instead of just the dateString
  const locale = _getDocumentLocale();
  const time = date.toLocaleString(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return {
    day: date.getDate(),
    month: date.toLocaleString(locale, { month: 'long' }),
    year: date.toLocaleString(locale, {year: 'numeric'}),
    time: time,
  };
}

//adding the following to support the above override of the bigDate formatter
export function _getProfileFieldAtKeyPath(profile, keypath) {
  const paths = keypath.split('.');

  if (!paths.length) {
    console.error('invalid key path', keypath);
    return null;
  }

  return paths.reduce((haystack, needleKey) => {
    if (!haystack) {
      console.log('haystack was null or undefined', haystack, needleKey, idx);
      return null;
    }
    const needle = haystack[needleKey];
    if (!needle) {
      console.log('could not find ' + needleKey, haystack);
      return null;
    }

    return needle;
  }, profile);
}

export function _getDocumentLocale() {
  return document.documentElement.lang.replace('_', '-');
}

export function betterTime(stamp) {
  const offset = new Date(stamp).getTimezoneOffset() / 60;
  const offsetMinute = new Date(stamp).getTimezoneOffset() % 60;
  let offsetMinuteString = `${Math.abs(offsetMinute)}`;
  if (offsetMinuteString.length === 1) { offsetMinuteString = `0${offsetMinuteString}`};
  const offsetStr = (offset < 0 ? '+0' : '-0') + Math.floor(Math.abs(offset)) + ':' + offsetMinuteString;
  return new Date(stamp + offsetStr);
}